<template>
<div>
    <Loading v-if="$global.state.loading" />

    <v-row align="center" class="mb-3">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class="btn secondary--text">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة منتج</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" v-model="$global.state.filter.search" label="ابحث هنا"
                color="info" hide-details prepend-inner-icon="search" dense outlined filled />
        </v-col>
        <v-col cols="12" md="3">
            <v-autocomplete v-model="$global.state.categoryId" @input="getItems()" :items="$global.state.listCategories"
                :loading="$global.state.loading" clearable item-text="name" item-value="id" dense color="info"
                placeholder="القـسم" no-data-text="لا توجد بيانات" hide-details outlined filled />
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="3" sm="6" v-for="(item, i) in $global.state.products" :key="i">
            <v-card class="border-sm-card animation-card cursor-pointer" @click="getDetails(item)">
                <v-img :src="item.mainImageUrl ? item.mainImageUrl : '@/assets/img/logo2.svg'" height="100">
                    <v-img v-if="!item.mainImageUrl" src="@/assets/img/logo2.svg" height="100"></v-img>
                    <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
                        <v-menu bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn x-small fab depressed v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="openEditDialog(item)">
                                    <v-list-item-title>
                                        <v-icon color="info">edit</v-icon>
                                        <span class="mx-2">تعديل</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="deleteItem(item.id, item.index)">
                                    <v-list-item-title>
                                        <v-icon color="error">delete</v-icon>
                                        <span class="mx-2">حذف</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-spacer></v-spacer>
                        <v-chip class="secondary--text" color="primary" v-text="item.categoryName"></v-chip>
                    </v-app-bar>
                </v-img>

                <v-card-subtitle>
                    <h3 v-text="item.name" class="my-2"></h3>
                    <div> {{ item.description.length > 40 ? item.description.substring(0, 40) + " ..." :
                        item.description }} </div>
                    <div class="grey--text d-flex justify-space-between">
                        <div>{{ item.created | formatDate }}</div>
                        <div>
                            <span class="primary--text text--darken-1 ml-1">{{ item.sizes.length }}</span>
                            <small>قياسات</small>
                        </div>
                    </div>
                </v-card-subtitle>
            </v-card>
        </v-col>
    </v-row>

    <div class="d-flex justify-space-between py-3">
        <v-spacer></v-spacer>
        <Pagination />
    </div>
    <Dialog />
</div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {};
    },

    mounted() {
        this.getItems();
        this.getListCategories();

        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },

        getListCategories() {
            this.$global.dispatch(`getListCategories`);
        },

        getDetails(item) {
            this.$router.push({
                name: "productDetails",
                params: {
                    obj: item,
                },
            });
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            // this.$genericService.swalAlertSuccess();
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.products.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setProductDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            let newItem = {
                categoryId: item.categoryId,
                name: item.name,
                description: item.description,
                videoUrl: item.videoUrl,
                attachments: item.attachments,
                sizes: []
            }

            newItem.sizes = item.sizes.map(size => {

                return {
                    name: size.name,
                    description: size.description,
                    price: size.price.toString(),
                    discount: size.discount.toString(),
                    minNoSale: size.minNoSale
                }
            });
            this.$store.commit("setItemDetails", newItem);
            this.$store.commit("setProductDialog");
            this.$eventBus.$emit("fill-fields");
        },
        getRegions() {
            this.$global.dispatch(`getRegions`);
        },
    },
};
</script>

<style lang="scss" scoped></style>
